import { AgentType, PackagePromotions, PackageType } from '~/types/create-agent-form-type';

export const packages: { [key: string]: PackageType } = {
    SELLING_AGENT: {
        name: 'SELLING AGENT',
        category: null,
        type: AgentType.SELLER,
        price: 0,
        package: null,
        isFree: true,
    },
    BUYING_AGENT: {
        name: 'BUYING AGENT',
        category: 'BASIC',
        type: AgentType.BUYER,
        price: 97,
        package: PackagePromotions.BUYER_BASIC_MONTHLY,
        isFree: false,
        couponCodes: {
            OSPVIP: {
                originalAmount: 1164,
                title: 'Pay for 9 months get 12',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-23T13:59:00+0000',
                frequency: 'annual',
            },
            BAIVIP: {
                originalAmount: 1164,
                title: 'Pay for 9 months get 12',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-14T13:59:00+0000',
                frequency: 'annual',
            },
            BAIVIP30: {
                originalAmount: 97,
                title: 'Free 30 day trial',
                finalAmount: 97,
                // disclaimer: null,
                expirationDate: '2024-06-23T13:59:00+0000',
                frequency: 'monthly',
            },
            OSPVIP30: {
                originalAmount: 1164,
                title: 'Pay for 9 months get 12 with 30 days trial',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-28T13:59:00+0000',
                frequency: 'annual',
            },
            ZACKYPANNUAL: {
                originalAmount: 1164,
                title: 'Pay for 9 months get 12 with 30 days trial',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-21T13:59:00+0000',
                frequency: 'annual',
            },
            ZACKYP: {
                originalAmount: 97,
                title: 'Free 30 day trial',
                finalAmount: 97,
                expirationDate: '2024-06-21T13:59:00+0000',
                frequency: 'monthly',
            },
            BASIC26: {
                originalAmount: 1164,
                title: 'Pay for 9 months get 12',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-28T13:59:00+0000',
                frequency: 'annual',
            },
        },
    },
    ADVANCED: {
        name: 'DEALS',
        category: 'DEALS',
        type: AgentType.BUYER,
        price: 297,
        package: PackagePromotions.BUYER_ADVANCED_MONTHLY,
        couponCodes: {
            DEALS20: {
                originalAmount: 297,
                title: '20% Discount',
                finalAmount: 238,
                disclaimer: 'Coupon code is available to first month only.',
                expirationDate: '2024-08-15T13:59:00+0000',
                frequency: 'monthly',
            },
            OFFMARKETDEALS: {
                originalAmount: 1782,
                title: '$784 Discount. Non-refundable',
                finalAmount: 998,
                disclaimer: 'Coupon code is available to first subscription only.',
                expirationDate: '2024-08-15T13:59:00+0000',
                frequency: 'bi-annual',
            },
            OVERFLOW: {
                originalAmount: 1782,
                title: '$784 Discount. Non-refundable',
                finalAmount: 998,
                disclaimer: 'Coupon code is available to first subscription only.',
                expirationDate: '2024-10-02T13:59:00+0000',
                frequency: 'bi-annual',
            },
        },
        isFree: false,
    },
    BUYING_AGENT_FREE: {
        name: 'BUYING AGENT FREE',
        category: 'FREE',
        type: AgentType.BUYER,
        price: 0,
        package: PackagePromotions.BUYER_FREE,
        couponCodes: {},
        isFree: true,
    },
    PRO: {
        name: 'PRO',
        category: 'PRO',
        type: AgentType.BUYER,
        price: 1997,
        package: null,
        isFree: false,
    },
};
